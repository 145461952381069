// Commission - 30%, Others
// Hospital - 30%, Others, Add rider
// Regular - 50%, 45%, 40%, 35%, 16%, 15%, Others
// Single Premium - 4.5%, 4.0%, 1.5%, Others, Top-up



import React, { useEffect, useState } from "react";
import { View, Card, Text, ExpandableSection } from "react-native-ui-lib";
import { CustomNumberInput, CustomSelect, Label, SecondaryButton } from "../../../mycomponents/DynamicForm";
import { DailyActivityCard } from "../../../screens/MainScreens/Custom/Internal_Pages/taqwa/DailyActivityScreen";
import { Platform } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Icon } from "react-native-eva-icons";

import { api } from "../../../services/api";
import { RND } from "../../accountancy/transaction";

export const SalesActivityExpandable = [
    {
        label: 'Accident Plan',
        fields: [
            {
                label: '',
                key: 'accident_plan_array_key',
                type: 'custom',
                visible: true,
                width: '100%',
                component: ({ field, useForm, tKey, tIndex }) => {
                    const obj = useForm((state) => state.formObject);
                    const setFormObject = useForm((state) => state.setFormObject);
                    const getFormObject = useForm((state) => state.getFormObject);
                    const accident_plan_array = useForm((state) => state.formObject.accident_plan_array) || [];

                    const updateMainState = (mainArray) => {
                        let TotalCASES = 0;
                        let TotalANP = 0;
                        let TotalFYP = 0;
                        let TotalFYC = 0;

                        for (let i = 0; i < (mainArray).length; i++) {
                            const item = mainArray[i];
                            TotalCASES += +(RND(item.cases || 0));
                            TotalANP += +(RND(item.anp || 0));
                            TotalFYP += +(RND(item.fyp || 0));
                            TotalFYC += +(RND(+(item.fyc || 0)));
                        }

                        setFormObject({
                            accident_plan_cases: TotalCASES,
                            accident_plan_anp: TotalANP,
                            accident_plan_fyc: RND(+(TotalFYC)),
                            accident_plan_fyp: TotalFYP,
                            updateVisiblity: Math.random()
                        });
                    }

                    useEffect(() => {
                        if (!accident_plan_array.length) {
                            updateMainState([]);
                            setFormObject({
                                accident_plan_array: [{
                                    type: 'Commission - 30%',
                                    cases: 0,
                                    anp: 0,
                                    fyp: 0,
                                    commission_percentage: 30,
                                    fyc: 0,
                                }]
                            });
                        }
                    }, []);

                    const addAccidentPlan = () => {
                        const array = (getFormObject().accident_plan_array || []);
                        array.push({
                            type: 'Commission - 30%',
                            cases: 0,
                            anp: 0,
                            fyp: 0,
                            commission_percentage: 30,
                            fyc: 0,
                        });
                        setFormObject({ accident_plan_array: array });
                    }

                    const removeAccidentPlan = (index) => {
                        const array = (getFormObject().accident_plan_array || []);
                        array.splice(index, 1);
                        setFormObject({ accident_plan_array: array });
                    }

                    return <View padding-10 backgroundColor="#eaeaea" style={{
                        width: '100%',
                        borderRadius: 10,
                    }}>
                        {(accident_plan_array || []).map((item, index) => {
                            return <View key={index.toString()} >
                                <View marginT-5 style={{
                                    width: '100%',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}>
                                    <View style={{
                                        flex: 1
                                    }}>
                                        {Label({ label: 'Payment Schedule' })}
                                        <CustomSelect
                                            value={item.payment_schedule}
                                            options={[
                                                { label: 'Monthly', value: 'Monthly' },
                                                { label: '2-Month', value: '2-Month' },
                                                { label: '3-Month', value: '3-Month' },
                                                { label: '4-Month', value: '4-Month' },
                                                { label: '6-Month', value: '6-Month' },
                                                { label: '12-Month', value: '12-Month' },
                                            ]}
                                            onChange={(value) => {
                                                const array = (getFormObject().accident_plan_array || []);
                                                array[index].payment_schedule = value;
                                                setFormObject({ accident_plan_array: array });
                                                updateMainState(array);
                                            }}
                                        />
                                    </View>

                                    <TouchableOpacity style={{
                                        marginTop: 30,
                                        padding: 10,
                                        marginLeft: 10,
                                        opacity: 1,
                                        backgroundColor: '#b41111',
                                        borderRadius: 5,
                                    }} onPress={() => removeAccidentPlan(index)}>
                                        <Icon name="trash-outline" width={16} height={16} fill="white" />
                                    </TouchableOpacity>
                                </View>

                                <View marginT-5 style={{
                                    width: '100%',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}>
                                    <View style={{
                                        flex: 1
                                    }}>
                                        {Label({ label: 'Type' })}
                                        <CustomSelect
                                            value={item.type}
                                            options={[
                                                { label: 'Commission - 30%', value: 'Commission - 30%' },
                                                { label: 'Others', value: 'Others' },
                                            ]}
                                            onChange={(value) => {
                                                const array = (getFormObject().accident_plan_array || []);
                                                array[index].type = value;

                                                if (value == 'Commission - 30%') {
                                                    array[index].commission_percentage = 30;
                                                }

                                                array[index].cases = 0;
                                                array[index].anp = 0;
                                                array[index].fyp = 0;
                                                array[index].fyc = 0;

                                                setFormObject({ accident_plan_array: array });
                                                updateMainState(array);
                                            }}
                                        />
                                    </View>

                                </View>

                                {!item.type?.includes("Commission") && <View marginT-5>
                                    {Label({ label: 'Commission Percentage' })}
                                    <CustomNumberInput
                                        value={item.commission_percentage}
                                        onChange={(value) => {
                                            const array = (getFormObject().accident_plan_array || []);
                                            array[index].commission_percentage = value;

                                            if (array[index].fyp) {
                                                const fyc = RND(item.fyp * value / 100);
                                                array[index].fyc = RND(fyc);
                                            }

                                            setFormObject({ accident_plan_array: array });
                                            updateMainState(array);
                                        }}
                                        placeholder="Enter Commission Percentage"
                                        currency={false}
                                    />
                                </View>}

                                {item.type?.includes("Top Up") || item.type?.includes("Add Rider") ? null : <View marginT-5>
                                    {Label({ label: 'Cases' })}
                                    <CustomNumberInput
                                        value={item.cases}
                                        onChange={(value) => {
                                            const array = (getFormObject().accident_plan_array || []);
                                            array[index].cases = value;
                                            setFormObject({ accident_plan_array: array });
                                            updateMainState(array);
                                        }}
                                        placeholder="Enter Cases"
                                        currency={false}
                                    />
                                </View>}

                                <View marginT-5>
                                    {Label({ label: 'ANP' })}
                                    <CustomNumberInput
                                        value={item.anp}
                                        onChange={(value) => {
                                            const array = (getFormObject().accident_plan_array || []);
                                            array[index].anp = value;

                                            setFormObject({ accident_plan_array: array });
                                            updateMainState(array);
                                        }}
                                        placeholder="Enter ANP"
                                        currency={true}
                                    />
                                </View>

                                <View marginT-5>
                                    {Label({ label: 'FYP' })}
                                    <CustomNumberInput
                                        value={item.fyp}
                                        onChange={(value) => {
                                            const array = (getFormObject().accident_plan_array || []);
                                            array[index].fyp = value;
                                            const fyc = value * (item.commission_percentage || 0) / 100;
                                            array[index].fyc = RND(fyc);

                                            setFormObject({ accident_plan_array: array });
                                            updateMainState(array);
                                        }}
                                        placeholder="Enter FYP"
                                        currency={true}
                                    />
                                </View>

                                <View marginT-5>
                                    {Label({ label: 'FYC' })}
                                    <CustomNumberInput
                                        value={item.fyc}
                                        onChange={(value) => {
                                            const array = (getFormObject().accident_plan_array || []);
                                            array[index].fyc = RND(value);
                                            setFormObject({ accident_plan_array: array });
                                            updateMainState(array);
                                        }}
                                        placeholder="Enter FYC"
                                        currency={true}
                                    />
                                </View>

                                {index != (accident_plan_array || []).length - 1 &&
                                    <>{Platform.OS == 'ios' ? <View style={{ overflow: 'hidden' }}>
                                        <View
                                            style={{
                                                borderStyle: 'dashed',
                                                borderWidth: 1,
                                                borderColor: '#d9d9d9',
                                                margin: -2,
                                                marginTop: 10,
                                            }}>
                                            <View style={{ height: 20, width: 200 }} />
                                        </View>
                                    </View> : <View style={{
                                        borderBottomColor: '#d9d9d9',
                                        borderBottomWidth: 1,
                                        width: '100%',
                                        marginBottom: 0,
                                        marginTop: 10,
                                        borderStyle: 'dashed',
                                    }} />}</>}
                            </View>
                        })}

                        <View marginV-10>
                            <SecondaryButton
                                label="Add"
                                onPress={() => addAccidentPlan()}
                            />
                        </View>
                    </View>
                }
            },
            {
                label: 'Cases',
                key: 'accident_plan_cases',
                type: 'number',
                placeholder: 'Enter Cases',
                visible: true,
                width: '100%',
            },
            {
                label: 'ANP',
                key: 'accident_plan_anp',
                type: 'number',
                placeholder: 'Enter ANP',
                visible: true,
                width: '100%',
                currency: true,
            },
            {
                label: 'FYP',
                key: 'accident_plan_fyp',
                type: 'number',
                placeholder: 'Enter FYP',
                visible: true,
                width: '100%',
                currency: true,
            },
            {
                label: 'FYC',
                key: 'accident_plan_fyc',
                type: 'number',
                placeholder: 'Enter FYC',
                visible: true,
                width: '100%',
                currency: true,
            }
        ]
    }, {
        label: 'Hospital Plan',
        fields: [
            {
                label: '',
                key: 'hospital_plan_array_key',
                type: 'custom',
                visible: true,
                width: '100%',
                component: ({ field, useForm, tKey, tIndex }) => {
                    const obj = useForm((state) => state.formObject);
                    const setFormObject = useForm((state) => state.setFormObject);
                    const getFormObject = useForm((state) => state.getFormObject);
                    const hospital_plan_array = useForm((state) => state.formObject.hospital_plan_array) || [];

                    const updateMainState = (mainArray) => {
                        let TotalCASES = 0;
                        let TotalANP = 0;
                        let TotalFYP = 0;
                        let TotalFYC = 0;

                        for (let i = 0; i < (mainArray).length; i++) {
                            const item = mainArray[i];
                            TotalCASES += +(RND(item.cases || 0));
                            TotalANP += +(RND(item.anp || 0));
                            TotalFYP += +(RND(item.fyp || 0));
                            TotalFYC += +(RND(item.fyc || 0));
                        }

                        setFormObject({
                            hospital_plan_cases: TotalCASES,
                            hospital_plan_anp: TotalANP,
                            hospital_plan_fyp: TotalFYP,
                            hospital_plan_fyc: TotalFYC,
                            updateVisiblity: Math.random()
                        });
                    }

                    useEffect(() => {
                        if (!hospital_plan_array.length) {
                            updateMainState([]);
                            setFormObject({
                                hospital_plan_array: [{
                                    type: 'Commission - 30%',
                                    cases: 0,
                                    anp: 0,
                                    fyp: 0,
                                    commission_percentage: 30,
                                    fyc: 0,
                                }]
                            });
                        }
                    }, []);

                    const addAccidentPlan = () => {
                        const array = (getFormObject().hospital_plan_array || []);
                        array.push({
                            type: 'Commission - 30%',
                            cases: 0,
                            anp: 0,
                            fyp: 0,
                            commission_percentage: 30,
                            fyc: 0,
                        });
                        setFormObject({ hospital_plan_array: array });
                    }

                    const removeAccidentPlan = (index) => {
                        const array = (getFormObject().hospital_plan_array || []);
                        array.splice(index, 1);
                        setFormObject({ hospital_plan_array: array });
                    }

                    return <View padding-10 backgroundColor="#eaeaea" style={{
                        width: '100%',
                        borderRadius: 10,
                    }}>
                        {(hospital_plan_array || []).map((item, index) => {
                            return <View key={index.toString()} >
                                <View marginT-5 style={{
                                    width: '100%',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}>
                                    <View style={{
                                        flex: 1
                                    }}>
                                        {Label({ label: 'Payment Schedule' })}
                                        <CustomSelect
                                            value={item.payment_schedule}
                                            options={[
                                                { label: 'Monthly', value: 'Monthly' },
                                                { label: '2-Month', value: '2-Month' },
                                                { label: '3-Month', value: '3-Month' },
                                                { label: '4-Month', value: '4-Month' },
                                                { label: '6-Month', value: '6-Month' },
                                                { label: '12-Month', value: '12-Month' },
                                            ]}
                                            onChange={(value) => {
                                                const array = (getFormObject().hospital_plan_array || []);
                                                array[index].payment_schedule = value;
                                                setFormObject({ hospital_plan_array: array });
                                                updateMainState(array);
                                            }}
                                        />
                                    </View>
                                    <TouchableOpacity style={{
                                        marginTop: 30,
                                        padding: 10,
                                        marginLeft: 10,
                                        opacity: 1,
                                        backgroundColor: '#b41111',
                                        borderRadius: 5,
                                    }} onPress={() => removeAccidentPlan(index)}>
                                        <Icon name="trash-outline" width={16} height={16} fill="white" />
                                    </TouchableOpacity>
                                </View>


                                <View marginT-5 style={{
                                    width: '100%',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}>
                                    <View style={{
                                        flex: 1
                                    }}>
                                        {Label({ label: 'Type' })}
                                        <CustomSelect
                                            value={item.type}
                                            options={[
                                                { label: 'Commission - 30%', value: 'Commission - 30%' },
                                                { label: 'Others', value: 'Others' },
                                                { label: 'Add Rider', value: 'Add Rider' },
                                            ]}
                                            onChange={(value) => {
                                                const array = (getFormObject().hospital_plan_array || []);
                                                array[index].type = value;

                                                if (value == 'Commission - 30%') {
                                                    array[index].commission_percentage = 30;
                                                }

                                                array[index].cases = 0;
                                                array[index].anp = 0;
                                                array[index].fyp = 0;
                                                array[index].fyc = 0;

                                                setFormObject({ hospital_plan_array: array });
                                                updateMainState(array);
                                            }}
                                        />
                                    </View>
                                </View>


                                {!item.type?.includes("Commission") && <View marginT-5>
                                    {Label({ label: 'Commission Percentage' })}
                                    <CustomNumberInput
                                        value={item.commission_percentage}
                                        onChange={(value) => {
                                            const array = (getFormObject().hospital_plan_array || []);
                                            array[index].commission_percentage = value;

                                            if (array[index].fyp) {
                                                const fyc = item.fyp * value / 100;
                                                array[index].fyc = fyc;
                                            }

                                            setFormObject({ hospital_plan_array: array });
                                            updateMainState(array);
                                        }}
                                        placeholder="Enter Commission Percentage"
                                        currency={false}
                                    />
                                </View>}

                                {item.type?.includes("Top Up") || item.type?.includes("Add Rider") ? null : <View marginT-5>
                                    {Label({ label: 'Cases' })}
                                    <CustomNumberInput
                                        value={item.cases}
                                        onChange={(value) => {
                                            const array = (getFormObject().hospital_plan_array || []);
                                            array[index].cases = value;
                                            setFormObject({ hospital_plan_array: array });
                                            updateMainState(array);
                                        }}
                                        placeholder="Enter Cases"
                                        currency={false}
                                    />
                                </View>}

                                <View marginT-5>
                                    {Label({ label: 'ANP' })}
                                    <CustomNumberInput
                                        value={item.anp}
                                        onChange={(value) => {
                                            const array = (getFormObject().hospital_plan_array || []);
                                            array[index].anp = value;
                                            setFormObject({ hospital_plan_array: array });
                                            updateMainState(array);
                                        }}
                                        placeholder="Enter ANP"
                                        currency={true}
                                    />
                                </View>

                                <View marginT-5>
                                    {Label({ label: 'FYP' })}
                                    <CustomNumberInput
                                        value={item.fyp}
                                        onChange={(value) => {
                                            const array = (getFormObject().hospital_plan_array || []);
                                            array[index].fyp = value;
                                            const fyc = value * (item.commission_percentage || 0) / 100;
                                            array[index].fyc = fyc;

                                            setFormObject({ hospital_plan_array: array });
                                            updateMainState(array);
                                        }}
                                        placeholder="Enter FYP"
                                        currency={true}
                                    />
                                </View>

                                <View marginT-5>
                                    {Label({ label: 'FYC' })}
                                    <CustomNumberInput
                                        value={item.fyc}
                                        onChange={(value) => {
                                            const array = (getFormObject().hospital_plan_array || []);
                                            array[index].fyc = value;
                                            setFormObject({ hospital_plan_array: array });
                                            updateMainState(array);
                                        }}
                                        placeholder="Enter FYC"
                                        currency={true}
                                    />
                                </View>

                                {index != (hospital_plan_array || []).length - 1 &&
                                    <>{Platform.OS == 'ios' ? <View style={{ overflow: 'hidden' }}>
                                        <View
                                            style={{
                                                borderStyle: 'dashed',
                                                borderWidth: 1,
                                                borderColor: '#d9d9d9',
                                                margin: -2,
                                                marginTop: 10,
                                            }}>
                                            <View style={{ height: 20, width: 200 }} />
                                        </View>
                                    </View> : <View style={{
                                        borderBottomColor: '#d9d9d9',
                                        borderBottomWidth: 1,
                                        width: '100%',
                                        marginBottom: 0,
                                        marginTop: 10,
                                        borderStyle: 'dashed',
                                    }} />}</>}
                            </View>
                        })}

                        <View marginV-10>
                            <SecondaryButton
                                label="Add"
                                onPress={() => addAccidentPlan()}
                            />
                        </View>
                    </View>
                }
            },
            {
                label: 'Cases',
                key: 'hospital_plan_cases',
                type: 'number',
                placeholder: 'Enter Cases',
                visible: true,
                width: '100%',
                currency: false,
            },
            {
                label: 'ANP',
                key: 'hospital_plan_anp',
                type: 'number',
                placeholder: 'Enter ANP',
                visible: true,
                width: '100%',
                currency: true,
            },
            {
                label: 'FYP',
                key: 'hospital_plan_fyp',
                type: 'number',
                placeholder: 'Enter FYP',
                visible: true,
                width: '100%',
                currency: true,
            },
            {
                label: 'FYC',
                key: 'hospital_plan_fyc',
                type: 'number',
                placeholder: 'Enter FYC',
                visible: true,
                width: '100%',
                currency: true,
            }
        ]
    }, {
        label: 'Regular Plan',
        fields: [
            {
                label: '',
                key: 'regular_plan_array_key',
                type: 'custom',
                visible: true,
                width: '100%',
                component: ({ field, useForm, tKey, tIndex }) => {
                    const obj = useForm((state) => state.formObject);
                    const setFormObject = useForm((state) => state.setFormObject);
                    const getFormObject = useForm((state) => state.getFormObject);
                    const regular_plan_array = useForm((state) => state.formObject.regular_plan_array) || [];

                    const updateMainState = (mainArray) => {
                        let TotalCASES = 0;
                        let TotalANP = 0;
                        let TotalFYP = 0;
                        let TotalFYC = 0;

                        for (let i = 0; i < (mainArray).length; i++) {
                            const item = mainArray[i];
                            TotalCASES += +(RND(item.cases || 0));
                            TotalANP += +(RND(item.anp || 0));
                            TotalFYP += +(RND(item.fyp || 0));
                            TotalFYC += +(RND(item.fyc || 0));
                        }

                        setFormObject({
                            regular_plan_cases: TotalCASES,
                            regular_plan_anp: TotalANP,
                            regular_plan_fyp: TotalFYP,
                            regular_plan_fyc: TotalFYC,
                            updateVisiblity: Math.random()
                        });
                    }

                    useEffect(() => {
                        if (!regular_plan_array.length) {
                            updateMainState([]);
                            setFormObject({
                                regular_plan_array: [{
                                    type: 'Commission - 50%',
                                    cases: 0,
                                    anp: 0,
                                    fyp: 0,
                                    commission_percentage: 50,
                                    fyc: 0,
                                }]
                            });
                        }
                    }, []);

                    const addAccidentPlan = () => {
                        const array = (getFormObject().regular_plan_array || []);
                        array.push({
                            type: 'Commission - 50%',
                            cases: 0,
                            anp: 0,
                            fyp: 0,
                            commission_percentage: 50,
                            fyc: 0,
                        });
                        setFormObject({ regular_plan_array: array });
                    }

                    const removeAccidentPlan = (index) => {
                        const array = (getFormObject().regular_plan_array || []);
                        array.splice(index, 1);
                        setFormObject({ regular_plan_array: array });
                    }

                    return <View padding-10 backgroundColor="#eaeaea" style={{
                        width: '100%',
                        borderRadius: 10,
                    }}>
                        {(regular_plan_array || []).map((item, index) => {
                            return <View key={index.toString()} >
                                <View marginT-5 style={{
                                    width: '100%',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}>
                                    <View style={{
                                        flex: 1
                                    }}>
                                        {Label({ label: 'Payment Schedule' })}
                                        <CustomSelect
                                            value={item.payment_schedule}
                                            options={[
                                                { label: 'Monthly', value: 'Monthly' },
                                                { label: '2-Month', value: '2-Month' },
                                                { label: '3-Month', value: '3-Month' },
                                                { label: '4-Month', value: '4-Month' },
                                                { label: '6-Month', value: '6-Month' },
                                                { label: '12-Month', value: '12-Month' },
                                            ]}
                                            onChange={(value) => {
                                                const array = (getFormObject().regular_plan_array || []);
                                                array[index].payment_schedule = value;
                                                setFormObject({ regular_plan_array: array });
                                                updateMainState(array);
                                            }}
                                        />
                                    </View>

                                    <TouchableOpacity style={{
                                        marginTop: 30,
                                        padding: 10,
                                        marginLeft: 10,
                                        opacity: 1,
                                        backgroundColor: '#b41111',
                                        borderRadius: 5,
                                    }} onPress={() => removeAccidentPlan(index)}>
                                        <Icon name="trash-outline" width={16} height={16} fill="white" />
                                    </TouchableOpacity>
                                </View>


                                <View marginT-5 style={{
                                    width: '100%',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}>
                                    <View style={{
                                        flex: 1
                                    }}>
                                        {Label({ label: 'Type' })}
                                        <CustomSelect
                                            value={item.type}
                                            options={[
                                                { label: 'Commission - 50%', value: 'Commission - 50%' },
                                                { label: 'Commission - 45%', value: 'Commission - 45%' },
                                                { label: 'Commission - 40%', value: 'Commission - 40%' },
                                                { label: 'Commission - 35%', value: 'Commission - 35%' },
                                                { label: 'Commission - 16%', value: 'Commission - 16%' },
                                                { label: 'Commission - 15%', value: 'Commission - 15%' },
                                                { label: 'Others', value: 'Others' },
                                                // { label: 'Add Rider', value: 'Add Rider' },
                                            ]}
                                            onChange={(value) => {
                                                const array = (getFormObject().regular_plan_array || []);
                                                array[index].type = value;

                                                const commissionMap = {
                                                    'Commission - 50%': 50,
                                                    'Commission - 45%': 45,
                                                    'Commission - 40%': 40,
                                                    'Commission - 35%': 35,
                                                    'Commission - 16%': 16,
                                                    'Commission - 15%': 15,
                                                }

                                                if (commissionMap[value]) {
                                                    array[index].commission_percentage = commissionMap[value];
                                                }

                                                array[index].cases = 0;
                                                array[index].anp = 0;
                                                array[index].fyp = 0;
                                                array[index].fyc = 0;

                                                setFormObject({ regular_plan_array: array });
                                                updateMainState(array);
                                            }}
                                        />
                                    </View>
                                </View>


                                {!item.type?.includes("Commission") && <View marginT-5>
                                    {Label({ label: 'Commission Percentage' })}
                                    <CustomNumberInput
                                        value={item.commission_percentage}
                                        onChange={(value) => {
                                            const array = (getFormObject().regular_plan_array || []);
                                            array[index].commission_percentage = value;

                                            if (array[index].fyp) {
                                                const fyc = item.fyp * value / 100;
                                                array[index].fyc = fyc;
                                            }

                                            setFormObject({ regular_plan_array: array });
                                            updateMainState(array);
                                        }}
                                        placeholder="Enter Commission Percentage"
                                        currency={false}
                                    />
                                </View>}

                                {item.type?.includes("Top Up") || item.type?.includes("Add Rider") ? null : <View marginT-5>
                                    {Label({ label: 'Cases' })}
                                    <CustomNumberInput
                                        value={item.cases}
                                        onChange={(value) => {
                                            const array = (getFormObject().regular_plan_array || []);
                                            array[index].cases = value;
                                            setFormObject({ regular_plan_array: array });
                                            updateMainState(array);
                                        }}
                                        placeholder="Enter Cases"
                                        currency={false}
                                    />
                                </View>}

                                <View marginT-5>
                                    {Label({ label: 'ANP' })}
                                    <CustomNumberInput
                                        value={item.anp}
                                        onChange={(value) => {
                                            const array = (getFormObject().regular_plan_array || []);
                                            array[index].anp = value;

                                            setFormObject({ regular_plan_array: array });
                                            updateMainState(array);
                                        }}
                                        placeholder="Enter ANP"
                                        currency={true}
                                    />
                                </View>

                                <View marginT-5>
                                    {Label({ label: 'FYP' })}
                                    <CustomNumberInput
                                        value={item.fyp}
                                        onChange={(value) => {
                                            const array = (getFormObject().regular_plan_array || []);
                                            array[index].fyp = value;
                                            const fyc = value * (item.commission_percentage || 0) / 100;
                                            array[index].fyc = fyc;

                                            console.log(array[index].fyc, fyc, value, item.commission_percentage);
                                            console.log(array);
                                            setFormObject({ regular_plan_array: array });
                                            updateMainState(array);
                                        }}
                                        placeholder="Enter FYP"
                                        currency={true}
                                    />
                                </View>

                                <View marginT-5>
                                    {Label({ label: 'FYC' })}
                                    <CustomNumberInput
                                        value={item.fyc}
                                        onChange={(value) => {
                                            const array = (getFormObject().regular_plan_array || []);
                                            array[index].fyc = value;
                                            setFormObject({ regular_plan_array: array });
                                            updateMainState(array);
                                        }}
                                        placeholder="Enter FYC"
                                        currency={true}
                                    />
                                </View>

                                {index != (regular_plan_array || []).length - 1 &&
                                    <>{Platform.OS == 'ios' ? <View style={{ overflow: 'hidden' }}>
                                        <View
                                            style={{
                                                borderStyle: 'dashed',
                                                borderWidth: 1,
                                                borderColor: '#d9d9d9',
                                                margin: -2,
                                                marginTop: 10,
                                            }}>
                                            <View style={{ height: 20, width: 200 }} />
                                        </View>
                                    </View> : <View style={{
                                        borderBottomColor: '#d9d9d9',
                                        borderBottomWidth: 1,
                                        width: '100%',
                                        marginBottom: 0,
                                        marginTop: 10,
                                        borderStyle: 'dashed',
                                    }} />}</>}
                            </View>
                        })}

                        <View marginV-10>
                            <SecondaryButton
                                label="Add"
                                onPress={() => addAccidentPlan()}
                            />
                        </View>
                    </View>
                }
            },
            {
                label: 'Cases',
                key: 'regular_plan_cases',
                type: 'number',
                placeholder: 'Enter Cases',
                visible: true,
                readOnly: true,
                width: '100%',
            },
            {
                label: 'ANP',
                key: 'regular_plan_anp',
                type: 'number',
                placeholder: 'Enter ANP',
                visible: true,
                readOnly: true,
                width: '100%',
                currency: true,
            },
            {
                label: 'FYP',
                key: 'regular_plan_fyp',
                type: 'number',
                placeholder: 'Enter FYP',
                visible: true,
                width: '100%',
                currency: true,
            },
            {
                label: 'FYC',
                key: 'regular_plan_fyc',
                type: 'number',
                placeholder: 'Enter FYC',
                visible: true,
                readOnly: true,
                width: '100%',
                currency: true,
            },

        ]
    }, {
        label: 'Single Premium Plan',
        fields: [
            {
                label: '',
                key: 'investment_plan_array_key',
                type: 'custom',
                visible: true,
                width: '100%',
                component: ({ field, useForm, tKey, tIndex }) => {
                    const obj = useForm((state) => state.formObject);
                    const setFormObject = useForm((state) => state.setFormObject);
                    const getFormObject = useForm((state) => state.getFormObject);
                    const investment_plan_array = useForm((state) => state.formObject.investment_plan_array) || [];

                    const updateMainState = (mainArray) => {
                        let TotalCASES = 0;
                        let TotalANP = 0;
                        let TotalFYC = 0;
                        let TotalFYP = 0;

                        for (let i = 0; i < (mainArray).length; i++) {
                            const item = mainArray[i];
                            TotalCASES += +(RND(item.cases || 0));
                            TotalANP += +(RND(item.anp || 0));
                            TotalFYC += +(RND(item.fyc || 0));
                            TotalFYP += +(RND(item.fyp || 0));
                        }

                        setFormObject({
                            investment_plan_cases: TotalCASES,
                            investment_plan_ianp: TotalANP,
                            investment_plan_fyc: TotalFYC,
                            investment_plan_fyp: TotalFYP,
                            updateVisiblity: Math.random()
                        });
                    }

                    useEffect(() => {
                        if (!investment_plan_array.length) {
                            updateMainState([]);
                            setFormObject({
                                investment_plan_array: [{
                                    type: 'Commission - 4.5%',
                                    cases: 0,
                                    anp: 0,
                                    fyp: 0,
                                    commission_percentage: 4.5,
                                    fyc: 0,
                                }]
                            });
                        }
                    }, []);

                    const addAccidentPlan = () => {
                        const array = (getFormObject().investment_plan_array || []);
                        array.push({
                            type: 'Commission - 4.5%',
                            cases: 0,
                            anp: 0,
                            fyp: 0,
                            commission_percentage: 4.5,
                            fyc: 0,
                        });
                        setFormObject({ investment_plan_array: array });
                    }

                    const removeAccidentPlan = (index) => {
                        const array = (getFormObject().investment_plan_array || []);
                        array.splice(index, 1);
                        setFormObject({ investment_plan_array: array });
                    }

                    return <View padding-10 backgroundColor="#eaeaea" style={{
                        width: '100%',
                        borderRadius: 10,
                    }}>
                        {(investment_plan_array || []).map((item, index) => {
                            return <View key={index.toString()} >

                                <View marginT-5 style={{
                                    width: '100%',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}>
                                    <View style={{
                                        flex: 1
                                    }}>
                                        {Label({ label: 'Payment Schedule' })}
                                        <CustomSelect
                                            value={item.payment_schedule}
                                            options={[
                                                { label: 'Monthly', value: 'Monthly' },
                                                { label: '2-Month', value: '2-Month' },
                                                { label: '3-Month', value: '3-Month' },
                                                { label: '4-Month', value: '4-Month' },
                                                { label: '6-Month', value: '6-Month' },
                                                { label: '12-Month', value: '12-Month' },
                                            ]}
                                            onChange={(value) => {
                                                const array = (getFormObject().investment_plan_array || []);
                                                array[index].payment_schedule = value;
                                                setFormObject({ investment_plan_array: array });
                                                updateMainState(array);
                                            }}
                                        />
                                    </View>

                                    <TouchableOpacity style={{
                                        marginTop: 30,
                                        padding: 10,
                                        marginLeft: 10,
                                        opacity: 1,
                                        backgroundColor: '#b41111',
                                        borderRadius: 5,
                                    }} onPress={() => removeAccidentPlan(index)}>
                                        <Icon name="trash-outline" width={16} height={16} fill="white" />
                                    </TouchableOpacity>
                                </View>

                                <View marginT-5 style={{
                                    width: '100%',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}>
                                    <View style={{
                                        flex: 1
                                    }}>
                                        {Label({ label: 'Type' })}
                                        <CustomSelect
                                            value={item.type}
                                            options={[
                                                { label: 'Commission - 4.5%', value: 'Commission - 4.5%' },
                                                { label: 'Commission - 4.0%', value: 'Commission - 4.0%' },
                                                { label: 'Commission - 1.5%', value: 'Commission - 1.5%' },
                                                { label: 'Others', value: 'Others' },
                                                { label: 'Top Up', value: 'Top Up' },
                                            ]}
                                            onChange={(value) => {
                                                const array = (getFormObject().investment_plan_array || []);
                                                array[index].type = value;

                                                const commissionMap = {
                                                    'Commission - 4.5%': 4.5,
                                                    'Commission - 4.0%': 4.0,
                                                    'Commission - 1.5%': 1.5,
                                                }

                                                if (commissionMap[value]) {
                                                    array[index].commission_percentage = commissionMap[value];
                                                }

                                                array[index].cases = 0;
                                                array[index].anp = 0;
                                                array[index].fyp = 0;
                                                array[index].fyc = 0;

                                                setFormObject({ investment_plan_array: array });
                                                updateMainState(array);
                                            }}
                                        />
                                    </View>
                                </View>

                                {!item.type?.includes("Commission") && <View marginT-5>
                                    {Label({ label: 'Commission Percentage' })}
                                    <CustomNumberInput
                                        value={item.commission_percentage}
                                        onChange={(value) => {
                                            const array = (getFormObject().investment_plan_array || []);
                                            array[index].commission_percentage = value;

                                            if (array[index].fyp) {
                                                const fyc = item.fyp * value / 100;
                                                array[index].fyc = fyc;
                                            }

                                            setFormObject({ investment_plan_array: array });
                                            updateMainState(array);
                                        }}
                                        placeholder="Enter Commission Percentage"
                                        currency={false}
                                    />
                                </View>}

                                {item.type?.includes("Top Up") || item.type?.includes("Add Rider") ? null : <View marginT-5>
                                    {Label({ label: 'Cases' })}
                                    <CustomNumberInput
                                        value={item.cases}
                                        onChange={(value) => {
                                            const array = (getFormObject().investment_plan_array || []);
                                            array[index].cases = value;
                                            setFormObject({ investment_plan_array: array });
                                            updateMainState(array);
                                        }}
                                        placeholder="Enter Cases"
                                        currency={false}
                                    />
                                </View>}

                                <View marginT-5>
                                    {Label({ label: 'ANP' })}
                                    <CustomNumberInput
                                        value={item.anp}
                                        onChange={(value) => {
                                            const array = (getFormObject().investment_plan_array || []);
                                            array[index].anp = value;

                                            setFormObject({ investment_plan_array: array });
                                            updateMainState(array);
                                        }}
                                        placeholder="Enter ANP"
                                        currency={true}
                                    />
                                </View>

                                <View marginT-5>
                                    {Label({ label: 'FYP' })}
                                    <CustomNumberInput
                                        value={item.fyp}
                                        onChange={(value) => {
                                            const array = (getFormObject().investment_plan_array || []);
                                            array[index].fyp = value;
                                            const fyc = value * (item.commission_percentage || 0) / 100;
                                            array[index].fyc = fyc;

                                            setFormObject({ investment_plan_array: array });
                                            updateMainState(array);
                                        }}
                                        placeholder="Enter FYP"
                                        currency={true}
                                    />
                                </View>

                                <View marginT-5>
                                    {Label({ label: 'FYC' })}
                                    <CustomNumberInput
                                        value={item.fyc}
                                        onChange={(value) => {
                                            const array = (getFormObject().investment_plan_array || []);
                                            array[index].fyc = value;
                                            setFormObject({ investment_plan_array: array });
                                            updateMainState(array);
                                        }}
                                        placeholder="Enter FYC"
                                        currency={true}
                                    />
                                </View>

                                {index != (investment_plan_array || []).length - 1 &&
                                    <>{Platform.OS == 'ios' ? <View style={{ overflow: 'hidden' }}>
                                        <View
                                            style={{
                                                borderStyle: 'dashed',
                                                borderWidth: 1,
                                                borderColor: '#d9d9d9',
                                                margin: -2,
                                                marginTop: 10,
                                            }}>
                                            <View style={{ height: 20, width: 200 }} />
                                        </View>
                                    </View> : <View style={{
                                        borderBottomColor: '#d9d9d9',
                                        borderBottomWidth: 1,
                                        width: '100%',
                                        marginBottom: 0,
                                        marginTop: 10,
                                        borderStyle: 'dashed',
                                    }} />}</>}
                            </View>
                        })}

                        <View marginV-10>
                            <SecondaryButton
                                label="Add"
                                onPress={() => addAccidentPlan()}
                            />
                        </View>
                    </View>
                }
            },
            {
                label: 'Cases',
                key: 'investment_plan_cases',
                type: 'number',
                placeholder: 'Enter Cases',
                visible: true,
                width: '100%',
            },
            {
                label: 'IANP',
                key: 'investment_plan_ianp',
                type: 'number',
                placeholder: 'Enter IANP',
                visible: true,
                width: '100%',
                currency: true,
            },
            {
                label: 'FYP',
                key: 'investment_plan_fyp',
                type: 'number',
                placeholder: 'Enter FYP',
                visible: true,
                width: '100%',
                currency: true,
            },
            {
                label: 'FYC',
                key: 'investment_plan_fyc',
                type: 'number',
                placeholder: 'Enter FYC',
                visible: true,
                width: '100%',
                currency: true,
            }
        ]
    },
    // {
    //     label: 'Single Premium Plan',
    //     fields: [
    //         {
    //             label: 'Cases',
    //             key: 'careshield_plan_cases',
    //             type: 'number',
    //             placeholder: 'Enter Cases',
    //             visible: true,
    //             width: '100%',
    //         },
    //         {
    //             label: 'ANP',
    //             key: 'careshield_plan_anp',
    //             type: 'number',
    //             placeholder: 'Enter ANP',
    //             visible: true,
    //             width: '100%',
    //             currency: true,
    //         },
    //         {
    //             label: 'FYC',
    //             key: 'careshield_plan_fyc',
    //             type: 'number',
    //             placeholder: 'Enter FYC',
    //             visible: true,
    //             width: '100%',
    //             currency: true,
    //         },
    //         // {
    //         //     label: 'FYP',
    //         //     key: 'careshield_plan_fyp',
    //         //     type: 'number',
    //         //     placeholder: 'Enter FYP',
    //         //     visible: true,
    //         //     width: '100%',
    //         //     currency: true,
    //         // },
    //     ]
    // }, 
    {
        label: 'MDRT',
        fields: [
            {
                label: 'YTD FYCC',
                key: 'mdrt_ytd_fycc',
                type: 'number',
                placeholder: 'Enter YTD FYCC',
                visible: true,
                width: '100%',
                currency: true,
            },
            {
                label: 'YTD Income',
                key: 'mdrt_ytd_income',
                type: 'number',
                placeholder: 'Enter YTD Income',
                visible: true,
                width: '100%',
                currency: true,
            },
            // {
            //     label: 'YTD FYP',
            //     key: 'mdrt_ytd_fyp',
            //     type: 'number',
            //     placeholder: 'Enter YTD FYP',
            //     visible: true,
            //     width: '100%',
            //     currency: true,
            // },
            // {
            //     label: 'Month To Date',
            //     key: 'mdrt_month_to_date',
            //     type: 'number',
            //     placeholder: 'Enter Month To Date',
            //     visible: true,
            //     width: '100%',
            //     currency: true,
            // },
        ]
    }, {
        label: 'Total MTD Month To Date',
        fields: [
            {
                label: 'Total CASES',
                key: 'total_cases',
                type: 'number',
                placeholder: 'Enter Total CASES',
                visible: true,
                width: '100%',
            },
            {
                label: 'Total ANP',
                key: 'total_anp',
                type: 'number',
                placeholder: 'Enter Total ANP',
                visible: true,
                width: '100%',
                currency: true,
            },
            {
                label: 'Total FYC Delivered \n for the Month (excl Pourover)',
                key: 'total_fyc',
                type: 'number',
                placeholder: 'Enter Total FYC Delivered for the Month (excl Pourover)',
                visible: true,
                width: '100%',
                currency: true,
            },
            // {
            //     label: 'Total FYP',
            //     key: 'total_fyp',
            //     type: 'number',
            //     placeholder: 'Enter Total FYP',
            //     visible: true,
            //     width: '100%',
            //     currency: true,
            // },
        ]
    }, {
        label: 'Year To Date',
        fields: [
            {
                label: 'CASES',
                key: 'year_to_date_cases',
                type: 'number',
                placeholder: 'Enter CASES',
                visible: true,
                width: '100%',
            },
            {
                label: 'CONVENTION ANP',
                key: 'year_to_date_convention_anp',
                type: 'number',
                placeholder: 'Enter CONVENTION ANP',
                visible: true,
                width: '100%',
                currency: true,
            },
            {
                label: 'FYC',
                key: 'year_to_date_fyc',
                type: 'number',
                placeholder: 'Enter FYC',
                visible: true,
                width: '100%',
                currency: true,
            },
            // {
            //     label: 'FYP',
            //     key: 'year_to_date_fyp',
            //     type: 'number',
            //     placeholder: 'Enter FYP',
            //     visible: true,
            //     width: '100%',
            //     currency: true,
            // },
        ]
    },
];

// globalData, setGlobalData, message, navigationFn
export const salesActivityfields = (response, view = false, moduleName = null, OpenModalCustom, value, isEdit, globalData, setGlobalData, message, navigationFn) => {

    const SalesActivityComponent = ({ field, useForm, tKey, tIndex }) => {
        const employee_id = useForm((state) => state.formObject['_id']);
        const [date, setDate] = useState(new Date());
        const [isEdit, setIsEdit] = useState(false);
        const [id, setId] = useState(null);
        const [expandedSection, setExpandedSection] = useState(null);

        const obj = useForm((state) => state.formObject);
        const setFormObject = useForm((state) => state.setFormObject);
        const getFormObject = useForm((state) => state.getFormObject);

        const updateTotal = () => {
            const obj = getFormObject();

            let old_total_cases = obj.total_cases;
            let old_total_anp = obj.total_anp;
            let old_total_fyc = obj.total_fyc;
            let old_total_fyp = obj.total_fyp;


            let TotalCASES = 0;
            let TotalANP = 0;
            let TotalFYC = 0;
            let TotalFYP = 0;

            // ignore Year To Date and MDRT and Total CASES
            // for rest of the fields calculate the total and set it to the total fields

            for (let i = 0; i < SalesActivityExpandable.length - 3; i++) {
                const item = SalesActivityExpandable[i];
                for (let j = 0; j < item.fields.length; j++) {
                    const field = item.fields[j];
                    if (obj[field.key]) {
                        if (field.key.includes('cases')) {
                            TotalCASES += obj[field.key] ? +obj[field.key] : 0;
                        }
                        if (field.key.includes('anp')) {
                            TotalANP += obj[field.key] ? +obj[field.key] : 0;
                        }
                        if (field.key.includes('fyc')) {
                            TotalFYC += obj[field.key] ? +obj[field.key] : 0;
                        }
                        if (field.key.includes('fyp')) {
                            TotalFYP += obj[field.key] ? +obj[field.key] : 0;
                        }
                    }
                }
            }

            // compare the old and new values and update the total fields only if there is a change
            if (old_total_cases != TotalCASES || old_total_anp != TotalANP || old_total_fyc != TotalFYC || old_total_fyp != TotalFYP) {
                setFormObject({
                    total_cases: TotalCASES,
                    total_anp: TotalANP,
                    total_fyc: TotalFYC,
                    total_fyp: TotalFYP,
                    updateVisiblity: Math.random()
                });
            }
        }

        useEffect(() => {
            updateTotal();
        }, [obj.updateVisiblity]);

        return <>
            <View style={{
                width: '100%',
            }}>
                {SalesActivityExpandable.map((item, index) => {
                    return <ExpandableSection
                        key={index.toString()}
                        sectionHeader={
                            <View flex centerV marginB-0 style={{
                                width: '100%',
                                marginTop: Platform.OS != 'web' ? 0 : 10,
                            }}>
                                <Text h1 style={{
                                    marginTop: Platform.OS != 'web' ? 0 : 10,
                                }}>{item.label}</Text>
                                {Platform.OS == 'ios' ? <View style={{ overflow: 'hidden' }}>
                                    <View
                                        style={{
                                            borderStyle: 'dashed',
                                            borderWidth: 1,
                                            borderColor: '#d9d9d9',
                                            margin: -2,
                                            marginTop: 10,
                                        }}>
                                        <View style={{ height: 20, width: 200 }} />
                                    </View>
                                </View> : <View style={{
                                    borderBottomColor: '#d9d9d9',
                                    borderBottomWidth: 1,
                                    width: '100%',
                                    marginBottom: 0,
                                    marginTop: 10,
                                    borderStyle: 'dashed',
                                }} />}
                            </View>
                        }
                        expanded={expandedSection === index}
                        onPress={() => {
                            if (expandedSection === index) {
                                setExpandedSection(null);
                            } else {
                                setExpandedSection(index);
                            }
                        }}
                    >
                        {item.fields.map((field, field_index) => {
                            if (field.type === 'number') {
                                return <View key={field_index.toString()}>
                                    <View centerV style={{
                                        marginBottom: Platform.OS != 'web' ? 0 : 10,
                                    }}>
                                        <View style={{ flexBasis: '40%' }}>
                                            {Label({ label: field.label }, view)}
                                        </View>

                                        <View row style={{ flexBasis: '60%' }}>
                                            {!view ? <CustomNumberInput
                                                value={obj[field.key]}
                                                onChange={(value) => {
                                                    setFormObject({ [field.key]: value });
                                                }}
                                                placeholder={field.placeholder}
                                                currency={field.currency}
                                                readOnly={field.readOnly || false}
                                                disabled={field.disabled || false}
                                            /> : <Text>{obj[field.key] >= 0 || obj[field.key] <= 0 ? `${field.currency ? "S$ " : ""}` + obj[field.key] : ''}</Text>}
                                        </View>
                                    </View>
                                    {Platform.OS != 'web' && <View style={{ width: '100%', height: 1, backgroundColor: '#f0f0f0', marginVertical: 0, marginBottom: field_index === item.fields.length - 1 ? 10 : 0 }} />}
                                </View>
                            }
                            if (field.type === 'custom') {
                                return field.component({ field, useForm, tKey, tIndex });
                            }
                        }
                        )}
                    </ExpandableSection>
                })}
            </View>
        </>;
    };

    const AutoSaveComponent = ({ field, useForm, tKey, tIndex }) => {
        const obj = useForm((state) => state.formObject);

        useEffect(() => {
            const timeout = setTimeout(() => {
                const newObj = { ...obj };
                newObj.is_auto_save = true;
                api.update("SalesActivities", newObj, obj._id).then((res) => {

                });
            }, 1000);

            return () => clearTimeout(timeout);
        }, [obj]);

        return <></>;
    }

    const formFields = [
        {
            type: "custom",
            tab: 'Sales Activity',
            visible: true,
            width: '100%',
            component: AutoSaveComponent,
        },
        {
            label: 'Date',
            key: 'date',
            type: 'date',
            placeholder: 'Enter Date',
            visible: true,
            width: '100%',
            value: new Date(),
            tab: 'Sales Activity',
            pickerType: 'month',
            disabled: Platform.OS != 'web',
        },
        {
            label: 'Agent',
            key: 'employee_id',
            type: 'select',
            placeholder: 'Select Agent',
            visible: false,
            width: '100%',
            options: response.Employees ? response.Employees.map((item) => {
                return { label: item.name, value: item._id }
            }) : [],
            value: globalData?.employee_id,
        },
        {
            key: 'SalesActivityComponent',
            type: 'custom',
            visible: true,
            width: '100%',
            tab: 'Sales Activity',
            component: SalesActivityComponent,
        },
        {
            label: 'Sales Remarks',
            key: 'sales_remarks',
            type: 'textarea',
            placeholder: 'Enter Sales Remarks',
            visible: true,
            width: '100%',
            tab: 'Sales Activity',
        },
    ];

    const CustomFields = response.CustomFields;

    if (CustomFields && CustomFields.length > 0) {
        CustomFields.forEach((field) => {
            formFields.push({
                label: field.name,
                key: 'cf.' + field.key,
                type: field.type,
                placeholder: field.placeholder,
                visible: true,
                width: '100%'
            });
        });
    }

    return formFields;
}

export const salesActivityMain = {
    fields: salesActivityfields,
    mobileCard: null
}